import { useWakeLock } from 'react-screen-wake-lock';

function WakeLock() {
  const { isSupported, released, request, release } = useWakeLock({
    onRequest: () => alert('Screen Wake Lock: requested!'),
    onError: () => alert('An error happened 💥'),
    onRelease: () => alert('Screen Wake Lock: released!'),
  });

  return (
    <div className=' transcriptionLog'><div className='flexHorizontal'>
      <p>
        Wake Lock support: <b>{`${isSupported}`}</b> Active: <b>{`${!released}`} &nbsp;</b>
      </p>
      <button
        type="button"
        onClick={() => (released === false ? release() : request())}
      >
        {released === false ? 'Release' : 'Request'}
      </button>
    </div></div>
  );
}

export default WakeLock;