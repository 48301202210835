import { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Auth, User } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Option, fromNullable } from 'fp-ts/Option';

// Configure FirebaseUI.
const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      //firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

export type LoginProps = {
    auth: Auth,
    onUserChanged: (user: Option<User>) => void
}

const Login = ({ auth, onUserChanged }: LoginProps): JSX.Element => {
    // const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  
    // const apps = firebase?.apps 
    // const app = firebase?.apps && firebase?.apps[0]
    // const auth = getAuth(app)
    //const db = firebase.firestore(); need to use getfirestore() for the new api

    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            // setIsSignedIn(!!user);
            onUserChanged(fromNullable(user));
        });

        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, [auth]);

    // if (!isSignedIn) {
    return (
        <div>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </div>
    );
    // }
    
    // const name = auth.currentUser?.displayName

    //TODO: split this off into a separate app component
    // return <UILayout auth={auth} />
}


export default Login