import type { Option } from "fp-ts/Option";
import * as O from "fp-ts/Option";
import { BsSoundwave } from "react-icons/bs";
import { FiUser } from "react-icons/fi";

export type ParticipantListProps = {
    participants:  Array<string>;
    speaking: Option<string>
}

const ParticipantList = ({ participants, speaking }:ParticipantListProps): JSX.Element => {

    return (
        <div className='participantList'>
            {participants.map(user => 
                <Participant user={user} key={user} active={O.isSome(speaking) && speaking.value === user} />
            )}
        </div>
    )
}

export type ParticipantProps = {
    user: string;
    active: boolean;
}

const Participant = ({ user, active }:ParticipantProps): JSX.Element => {

    return <div className={active ? "participant participantSpeaking" : "participant"}>
        <ParticipantStatus speaking={active} />{user}
    </div>
}

export type ParticipantStatusProps = {
    speaking: boolean;
}

const ParticipantStatus = ({ speaking }:ParticipantStatusProps): JSX.Element => {
    if (speaking) {
        return <div className="participantStatus"><BsSoundwave /></div>
    } else {
        return <div className="participantStatus"><FiUser /></div>
    }
}

export default ParticipantList;