
export type Transcript = {
    user: string, text: string 
}
export type TranscribeWidgetProps = {
    transcripts: Transcript[]
}

const TranscribeWidget = ({ transcripts }:TranscribeWidgetProps): JSX.Element => {
    console.log(transcripts)
    return (
        <div className="transcriptionLog">
            {transcripts.map(({user, text}, i) => {
                return <div key={`texttranscript${i}-${user}: ${text}`}>{user.split('|')[0]}: {text}</div> //i hate this but it works and it's late
            })}
        </div>
    )
}

export default TranscribeWidget;

