import { useState } from "react"
import OnOffSwitch from "../OnOffSwitch/OnOffSwitch"

const wherebyLink = "https://currents.whereby.com/common"

const Whereby = () => {
    const [open, setOpen] = useState<boolean>(false)

    return <div className='flexVertical wherebyHolder' >
        
        <div className="flexHorizontal ">
            <div>WHEREBY PORTAL:</div>
            <OnOffSwitch state={open} onToggle={() => setOpen(!open)} connecting={false}/>
            <div style={{width: 48}} />
            <button onClick={() => window.open(wherebyLink, "_blank")}>OPEN IN TAB</button>
        </div>
        
        {open ? 
            <iframe src={wherebyLink} allow="camera *;microphone *" className="whereby"/> :
            null
        }
        
    </div>
}

export default Whereby