import { GiSoundWaves } from 'react-icons/gi';
import { TbMicrophone2, TbMicrophone2Off } from 'react-icons/tb';
import type { Option } from 'fp-ts/Option';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/lib/function';

export enum ButtonStatus {
  OFF,
  ON,
  BLOCKED,
  ACTIVE
}

export type BigRedButtonProps = {
    speakingUser: Option<string>;
    status: ButtonStatus;
    onStart: () => void;
    onStop: () => void;
}

const generateClassName = (buttonState: ButtonStatus): string => {
    const className = 'pushToTalk'

    switch (buttonState) {
        case ButtonStatus.ON:
            return className;
        case ButtonStatus.OFF:
            return `${className} pushOff`;
        case ButtonStatus.BLOCKED:
            return `${className} pushBlocked`;
        case ButtonStatus.ACTIVE:
            return `${className} pushActive`;
    }
}

export function BigRedButton({speakingUser, status, onStart, onStop}: BigRedButtonProps): JSX.Element {

    const buttonText = (): JSX.Element => {
        switch (status) {
            case ButtonStatus.ON:
                return <>PUSH TO TALK</>
            case ButtonStatus.ACTIVE: 
                return (
                    <div className='flexVertical'>
                        <TbMicrophone2 size={24}/>
                        <div style={{height: 8}} />
                        <>SPEAK NOW</>
                    </div>
                )
            case ButtonStatus.OFF: 
                return <TbMicrophone2Off size={24}/>
            case ButtonStatus.BLOCKED: 
                return (
                    <div className='flexVertical'>
                        <GiSoundWaves size={24}/>
                        <div style={{height: 8}} />
                        <>PLEASE WAIT</>
                    </div>
                );
        }
    }

    //three states: off, on, and blocked
    // return (
    //     <button 
    //         onMouseDown={onStart}
    //         onTouchStart={onStart}
                
    //         onTouchEnd={onStop}
    //         onTouchCancel={onStop}
    //         onMouseUp={onStop} 

    //         className={generateClassName(status)}
                
    //     >
    //         <div className='pushButton'>
    //             {buttonText()}
    //         </div>
    //     </button>
    // );
    return (
        <div className='buttonHolder'>
            <div className='buttonHolderActive' style={{display: status === ButtonStatus.ACTIVE ? 'block' : 'none'}} />
            <div className='buttonHolderUnderlay' />
            <button 
                onMouseDown={onStart}
                onTouchStart={onStart}
                    
                // onTouchEnd={onStop}
                // onTouchCancel={onStop}
                // onMouseUp={onStop} 

                className={generateClassName(status)}
                    
            >
                <div className='pushButton'>
                    <ButtonText bstate={status} speakingUser={speakingUser} />
                </div>
            </button>
        </div>
    )
}


type ButtonStatusProps = {
    bstate: ButtonStatus;
    speakingUser: Option<string>;
}

const ButtonText = ({ bstate, speakingUser }: ButtonStatusProps): JSX.Element => {
    const user: string = pipe(
       speakingUser,
       O.getOrElse(() => 'SOMEONE')
    );

    switch(bstate) {
        case ButtonStatus.ON:
            return <div className='flexVertical'>'PUSH TO TALK'</div>
        case ButtonStatus.ACTIVE:
            return <div className='flexVertical'><TbMicrophone2 size={24}/><div style={{height: 8}} /><>SPEAK NOW</></div>
        case ButtonStatus.OFF:
            return <div className='flexVertical'> <TbMicrophone2Off size={24}/><div style={{height: 8}} />OFF</div>
        case ButtonStatus.BLOCKED:
            return <div className='flexVertical'><GiSoundWaves size={24}/><div style={{height: 8}} />{user} IS SPEAKING</div>
    }
}