
export type OnOffSwitchProps = {
    state: boolean,
    onToggle: () => void,
    connecting: boolean
}

const OnOffSwitch = ({state, onToggle, connecting}:OnOffSwitchProps): JSX.Element => {
    return (<div className="flexHorizontal">
            <div className={`switchButton ${state ? ' switchButtonActive' : ''}`} onClick={onToggle}>
                <div className={`switchCircle ${state ? ' switchCircleOn' : ''}`}/>
            </div>
            {connecting ? <div className="transcriptionLog">[re]connecting...</div> : null}
        </div>
    );
}

export default OnOffSwitch;