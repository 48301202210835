import { Auth } from 'firebase/auth'
import type { Either } from 'fp-ts/Either'
import * as E from 'fp-ts/Either'
import type { Option } from 'fp-ts/Option'
import * as O from 'fp-ts/Option'
import { absurd, flow, pipe } from 'fp-ts/function'
import { useEffect, useMemo, useRef, useState } from 'react'
import NoSleep from 'nosleep.js';
import { BiLogOutCircle } from 'react-icons/bi'
import { IoMdSettings } from 'react-icons/io'
import { TextMessage, showDecodeError, textMessageCodec } from '../../utils/codecs'
import OnOffSwitch from '../OnOffSwitch/OnOffSwitch'
import ParticipantList from '../ParticipantList/ParticipantList'
import { BigRedButton, ButtonStatus } from '../PushToTalk/BigRedButton'
import TranscribeWidget from '../TranscribeWidget/TranscribeWidget'
import AudioRecorder from 'audio-recorder-polyfill'
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder' //for this to work, need to add index.d.ts to the node library, with declare module 'audio-recorder-polyfill'; 
import type { Transcript } from '../TranscribeWidget/TranscribeWidget'
import Whereby from '../Widgets/Whereby'
import Pomodoro from '../Widgets/Pomodoro'
import WakeLock from '../Widgets/WakeLock'
//import Mp3RecorderWorker from 'workerize-loader!../../worker.js';

AudioRecorder.encoder = mpegEncoder
AudioRecorder.prototype.mimeType = 'audio/mpeg'
const SAMPLE_RATE = 8000

export type UILayoutProps = {
    auth: Auth;
    user: string;
}

export type State = {
    speaking: Option<string>;
    participants: string[];
}

// export type Action = {
//     _tag: 'SpeakingChanged';
//     speaking: Option<string>;
// } | {

// }

type Message = TextMessage | {
    _tag: 'Binary';
    data: Blob;
}

const noSleep = new NoSleep();

const computeRMS = (buffer: AudioBuffer) =>  {
    const data = buffer.getChannelData(0); // get data for first channel
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
        sum += data[i] * data[i]; // square each sample and add to the sum
    }
    return Math.sqrt(sum / data.length); // return the square root of the average
}

const decodeMessage = (data: unknown): Either<string, Message> => {
    if (typeof data === 'string') {
        return pipe(
            E.tryCatch(() => JSON.parse(data), () => 'Failed to parse JSON'),
            E.chain(flow(textMessageCodec.decode, E.mapLeft(showDecodeError)))
        )
    } else if (data instanceof Blob) {
        return E.right({ _tag: 'Binary', data })
    } else {
        return E.left('Unknown message type')
    }
}

export function UILayout({user, auth}: UILayoutProps): JSX.Element {
    // const [buttonState, setButtonState] = useState(ButtonStatus.ON);
    const [participants, setParticipants] = useState<string[]>([]);
    const [speaking, setSpeaking] = useState<Option<string>>(O.none);
    const [transcripts, setTranscripts] = useState<Transcript[]>([]);
    const [switchState, setSwitchState] = useState(true);

    const socket = useRef<WebSocket | null>(null);
    //const mediaRecorder = useRef<MediaRecorder | null>(null);
    const mediaRecorder = useRef(new AudioRecorder());
    
    const audioSource = useRef<MediaStreamAudioSourceNode | null>(null); 
    const audioContext = useRef(new AudioContext({ sampleRate:SAMPLE_RATE}));
    const audioAppleContext = useRef(new AudioContext({ sampleRate:SAMPLE_RATE}));
    // const scriptProcessor = useRef<ScriptProcessorNode>(audioContext.current.createScriptProcessor(1024, 1, 1));

    // const mediaSource = useRef<MediaSource>(null);
    const sourceBuffer = useRef<SourceBuffer | null>(null);

    const [audioBufferQueue, setAudioBufferQueue] = useState<ArrayBuffer[]>([]); //needed to queue buffers if audio is busy

    const keepAliveInterval = useRef<ReturnType<typeof setInterval> | null>(null)
    const reconnectInterval = useRef<ReturnType<typeof setInterval> | null>(null)

    const audio = useRef<HTMLAudioElement | null>(null);

    const [nextTime, setNextTime] = useState(0)

    const [audioErrorNeedsClearing, setAudioErrorNeedsClearing] = useState<boolean>(false)
    const [recordingEventExclusion, setRecordingEventExclusion] = useState<boolean>(false)
    

    const checkWebmSupported_aka_isThisNotApple = () => MediaRecorder.isTypeSupported('audio/webm; codecs=opus')
    
    const handleMediaSource = () => {
        const ms = new MediaSource();
        ms.addEventListener('sourceopen', () => sourceBuffer.current = ms.addSourceBuffer('audio/mpeg'));
        audio.current = new Audio();
        
        audio.current.src = URL.createObjectURL(ms);
        audio.current.load()

        audio.current.autoplay = true;

        audio.current.onerror = () => {
            if (audio.current && audio.current.error?.code === 3) {
                // Clear the source buffer.
                if (sourceBuffer.current?.updating) {
                    sourceBuffer.current.abort();
                }
                // Close the MediaSource.
                try {
                    console.log('audio error, clear buffer and close media source')
                    console.error(audio.current.error)
                    ms.endOfStream();
                } catch(e) {
                    console.log(e)
                }
                setAudioErrorNeedsClearing(true)
                audio.current.pause();
                audio.current.currentTime = 0;
                //Just reload
                // audio.current.load();
                // Try to play the media again...
                }
        }

        
        if (sourceBuffer.current) {
            sourceBuffer.current.addEventListener('updateend', () => { //clear audio buffer if we're being slammed
                setAudioBufferQueue(prevQueue => {
                    if (prevQueue.length > 0) {
                        const newQueue = [...prevQueue]
                        const nextBuffer = newQueue.shift();
                        if (sourceBuffer.current && nextBuffer !== undefined) {
                            sourceBuffer.current.appendBuffer(nextBuffer);
                        }
                        return newQueue;
                    }
                    return prevQueue;
                });
            });
        }
    }

    useEffect(() => {
        if (audioErrorNeedsClearing) {
            handleMediaSource()
            setAudioErrorNeedsClearing(false)
        }
    }, [audioErrorNeedsClearing])


    const buildAudioContexts = () => {
        if(!audioContext.current) audioContext.current = new AudioContext({ sampleRate:SAMPLE_RATE})
        if(!audioAppleContext.current) audioAppleContext.current = new AudioContext({ sampleRate:SAMPLE_RATE})
    }

    const handleAudioBuffer = (isApple: boolean) => isApple ? 
        async (arrayBuffer: ArrayBuffer) => {
            const audioBuffer = await audioAppleContext.current.decodeAudioData(arrayBuffer);
                                    
            //connecting audio context to buffers
            const source = audioAppleContext.current.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(audioAppleContext.current.destination);

            //set gain 
            const rms = computeRMS(audioBuffer);
            var gainNode = audioAppleContext.current.createGain();
            if (rms < 0.05) gainNode.gain.value = 1.5; 
            else gainNode.gain.value = 1.0; 
            
            //connect gain node 
            gainNode.connect(audioAppleContext.current.destination);
            source.connect(gainNode);
            
            if (audioAppleContext.current.currentTime > nextTime) {
                setNextTime(audioAppleContext.current.currentTime);
            }
            
            source.start(nextTime);
            setNextTime(prevState => {
                if (source.buffer) return prevState + source.buffer.duration
                else return prevState
            }); // schedule when the next audio buffer will start 
        } : async (arrayBuffer: ArrayBuffer) => {
        if (sourceBuffer.current) {
            try {
                if (audio.current?.error) {
                    console.error(audio.current?.error)
                    setAudioBufferQueue(prevQueue => [...prevQueue, arrayBuffer]);
                    //handleMediaSource()
                } else if (!sourceBuffer.current.updating) {
                    sourceBuffer.current.appendBuffer(arrayBuffer); // The SourceBuffer is not currently being updated, so we can append the buffer immediately.
                } else {
                    setAudioBufferQueue(prevQueue => [...prevQueue, arrayBuffer]);// The SourceBuffer is currently being updated. We put the buffer in a queue to append it later.
                }
            } catch(e) {
                console.error(e)
            }
        }
        if (audio.current && audio.current.paused) {
            audio.current.play();
        }
    }

    const createSocket = () => {
        if (keepAliveInterval.current) clearInterval(keepAliveInterval.current); 
        socket.current = buildSocket()
        keepAliveInterval.current = setInterval(() => {
            if (socket.current && socket.current.readyState === WebSocket.OPEN) {
                socket.current.send(JSON.stringify({ _tag: 'Join', user }));
            }
        }, 20000); //send ping every 20 seconds



        navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
            audioSource.current = audioContext.current.createMediaStreamSource(stream);
            mediaRecorder.current = new AudioRecorder(stream);

            if (mediaRecorder.current) mediaRecorder.current.addEventListener("dataavailable", (event: BlobEvent) => {
                if (socket.current && socket.current.readyState === WebSocket.OPEN) {
                    socket.current.send(new Blob([event.data], { type: 'audio/mpeg'}));
                }
            });
        });

    }

    const reconnectSocket = () => {
        if (socket.current) socket.current.close();
        createSocket()
    }

    const maintainReconnectSocketInterval = () => {
        if (!reconnectInterval.current) {
            const reconnectTime = setInterval(() => {
                reconnectSocket()
            }, 3000)
            reconnectInterval.current = reconnectTime
        }
    }

    const buildSocket = () : WebSocket => {

        const ws = new WebSocket(process.env.REACT_APP_WS_URL || 'wss://ukiyo-e.currents.fm');
                
        ws.onopen = () => {
            console.log("WebSocket Client Connected");
            if(reconnectInterval.current) {
                clearInterval(reconnectInterval.current)
                reconnectInterval.current = null
            }
            setSwitchState(true)
            ws.send(JSON.stringify({ _tag: 'Join', user }));
            socket.current = ws;
            // keepAliveInterval.current = setInterval(() => {
            //     if (ws.readyState === WebSocket.OPEN) {
            //         ws.send(JSON.stringify({ _tag: 'Join', user }));
            //     }
            // }, 20000); //send ping every 20 seconds
        };

        ws.onmessage = async (event) => {
            if (!switchState) {
                return;
            } 

            const message = decodeMessage(event.data);
            if (E.isRight(message)) {
                switch (message.right._tag) {
                    case 'Busy':
                        setSpeaking(O.some(message.right.user));
                        if (checkWebmSupported_aka_isThisNotApple()) { //if this is not ios
                            try {
                                handleMediaSource()
                            } catch (e) {
                                console.log(e)
                            }
                        }

                        if (message.right.user === user && mediaRecorder.current) {
                            mediaRecorder.current.start(300)
                            //for use in stop handler, to only execute stop once if the state is recording
                            //implementing becuase 'broadcastFree doesn't contain the user that was freed
                            setRecordingEventExclusion(true) 
                        }
                        if (message.right.user !== user && mediaRecorder.current) {
                            mediaRecorder.current.stop();
                        }
                        break;
                    case 'Free':
                        setSpeaking(O.none);
                        setNextTime(0)
                        break;
                    case 'Users':
                        setParticipants(message.right.users);
                        break;
                    case 'Transcript':
                        const newTranscript = {...message.right}
                        setTranscripts((prevState: Transcript[]) => [...prevState, newTranscript]) //need to use prevstate so the log shows
                        break;
                    case 'Binary':
                        const arrayBuffer = await message.right.data.arrayBuffer();
                        buildAudioContexts()
                        handleAudioBuffer(!checkWebmSupported_aka_isThisNotApple())(arrayBuffer)
                        break;
                    default:
                        absurd(message.right);
                }
            }
            
        };

        ws.onclose = (e) => {
            console.log("ws onclose event", e)
            //socket.current = null;
            handleStopRecording()
            
            if (e.code === 1006) {
                maintainReconnectSocketInterval()
            }
            setSwitchState(false)
            setParticipants([]) //clear participants

        }
        ws.onerror = (e) => {
            console.log("ws error event", e)
            handleStopRecording()
            if (switchState) maintainReconnectSocketInterval()
        }

        

        return ws;
    }

    


    useEffect(
        //TODO check this code for ws reconnect https://stackoverflow.com/questions/51476104/websocket-closed-after-the-app-is-in-background-for-more-than-10-minutes-react?rq=4
        () => {

            if (switchState) {
                buildAudioContexts()
                //window.AudioContext = checkWebmSupported_aka_isThisNotApple() ? audioContext.current : audioAppleContext.current;
                if (socket.current?.readyState != WebSocket.OPEN) createSocket()
                return () => {
                    try {
                        audioContext.current.close();
                        audioAppleContext.current.close();
                    } catch (e) {
                        console.log(e)
                    }
                    if (socket.current) socket.current.close();
                };
            }
            // if (switchState) {
                
            //     //check audio context
            //     buildAudioContexts()

            //     // Setup WebSocket connection
            //     const ws = new WebSocket(process.env.REACT_APP_WS_URL || 'wss://ukiyo-e.currents.fm');
                
            //     ws.onopen = () => {
            //         console.log("WebSocket Client Connected");
            //         ws.send(JSON.stringify({ _tag: 'Join', user }));
            //         socket.current = ws;
            //         keepAliveInterval.current = setInterval(() => {
            //             if (ws.readyState === WebSocket.OPEN) {
            //                 ws.send('ping');
            //             }
            //         }, 20000); //send ping every 20 seconds
            //     };

            //     ws.onmessage = async (event) => {
            //         if (!switchState) {
            //             return;
            //         } 

            //         const message = decodeMessage(event.data);
            //         if (E.isRight(message)) {
            //             switch (message.right._tag) {
            //                 case 'Busy':
            //                     setSpeaking(O.some(message.right.user));

            //                     if (checkWebmSupported_aka_isThisNotApple()) { //if this is not ios
            //                         try {
            //                             handleMediaSource()
            //                         } catch (e) {
            //                             console.log(e)
            //                         }

            //                     }

            //                     if (message.right.user === user && mediaRecorder.current) {
            //                         mediaRecorder.current.start(300)
            //                     }
            //                     if (message.right.user !== user && mediaRecorder.current) {
            //                         mediaRecorder.current.stop();
            //                     }
            //                     // if (message.right.user === user && audioSource.current) {
            //                     //     console.log('speaking');
            //                     //     // const scriptProcessor = audioContext.current.createScriptProcessor(1024, 1, 1);

            //                     //     scriptProcessor.current.onaudioprocess = (event) => {
            //                     //         // console.log('sending audio')
            //                     //         const input = event.inputBuffer.getChannelData(0);
            //                     //         if (ws.readyState === WebSocket.OPEN) {
            //                     //             ws.send(input.buffer);
            //                     //         }
            //                     //     }
                
            //                     //     audioSource.current.connect(scriptProcessor.current);
            //                     //     scriptProcessor.current.connect(audioContext.current.destination);
            //                     // //     mediaRecorder.current.start();
            //                     // } else {
            //                     //     audioSource.current?.disconnect();
            //                     //     scriptProcessor.current.disconnect();
            //                     // //     mediaRecorder.current.stop();
            //                     // }
            //                     break;
            //                 case 'Free':
            //                     setSpeaking(O.none);
            //                     setNextTime(0)
            //                     break;
            //                 case 'Users':
            //                     setParticipants(message.right.users);
            //                     break;
            //                 case 'Transcript':
            //                     const newTranscript = {...message.right}
            //                     setTranscripts((prevState: Transcript[]) => [...prevState, newTranscript]) //need to use prevstate so the log shows
            //                     break;
            //                 case 'Binary':
            //                     const arrayBuffer = await message.right.data.arrayBuffer();
            //                     handleAudioBuffer(!checkWebmSupported_aka_isThisNotApple())(arrayBuffer)
            //                     break;
            //                 default:
            //                     absurd(message.right);
            //             }
            //         }
                    
            //     };

            //     ws.onclose = (e) => {
            //         console.log("ws onclose event", e)
            //         //socket.current = null;
            //         setSwitchState(false)
            //         setParticipants([]) //clear participants
            //         if (keepAliveInterval.current) clearInterval(keepAliveInterval.current); 
            //     }
            //     ws.onerror = (e) => {
            //         console.log("ws error event", e)
            //         socket.current = null;
            //         setTimeout(() => {
            //             //reconnect after 30 seconds
            //         }, 3000)
            //     }

                

                
                

            //     navigator.mediaDevices.getUserMedia({ audio: true })
            //         .then(stream => {
            //             audioSource.current = audioContext.current.createMediaStreamSource(stream);
            //             // const scriptProcessor = audioContext.current.createScriptProcessor(1024, 1, 1);
                        
            //             // pc.onicecandidate = event => {
            //             //     if (event.candidate) {
            //             //         ws.send(JSON.stringify())
            //             //     }
            //             // }

            //             // audioSource.current.connect(scriptProcessor);
            //             // scriptProcessor.connect(audioContext.current.destination);
                        

            //             //library that extends MediaRecorder to handle mp3 (most standard)
            //             mediaRecorder.current = new AudioRecorder(stream);

            //             if (mediaRecorder.current) mediaRecorder.current.addEventListener("dataavailable", (event: BlobEvent) => {
            //                 if (ws.readyState === WebSocket.OPEN) {
            //                     ws.send(new Blob([event.data], { type: 'audio/mpeg'}));
            //                 }
            //             });
            //             // mediaRecorder.current.ondataavailable = (event) => {
            //             //     // console.log(event.data);
            //             //     if (ws.readyState === WebSocket.OPEN) {
            //             //         ws.send(new Blob([event.data], { type: 'audio/mpeg'}));
            //             //     }
            //             // }
                        
            //             // mediaRecorder.current.ondataavailable = (event) => {
            //             //     // audioChunks.current.push(event.data);
            //             //     const reader = new FileReader();
            //             //     reader.onloadend = () => {
            //             //         if (ws.readyState === WebSocket.OPEN) {
            //             //             ws.send(reader.result as ArrayBuffer);
            //             //         }
            //             //     }
            //             //     reader.readAsArrayBuffer(event.data);
            //             // };

            //             // mediaRecorder.current.onstop = () => {
            //             //     // const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
            //             //     // const reader = new FileReader();
            //             //     // reader.onloadend = () => {
            //             //     //     if (ws.readyState === WebSocket.OPEN) {
            //             //     //         ws.send(reader.result as ArrayBuffer);
            //             //     //     }
            //             //     // };
            //             //     // reader.readAsArrayBuffer(audioBlob);
            //             //     // audioChunks.current = [];
            //             // };
            //         });


                
            //     return () => {
            //         // if (mediaRecorder.current) {
            //         //     mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
            //         // }
            //         try {
            //             audioContext.current.close();
            //         } catch (e) {
            //             console.log(e)
            //         }
            //         if (socket.current) socket.current.close();
            //     };
            // }
        }, 
        [switchState]
    );

    const handleStartRecording = () => {
        if (socket.current) {
            socket.current.send(JSON.stringify({ _tag: 'SpeakRequest' }));
            //TODO only do this if it's ios Safari; need to add checks for the future. this is because audioRecorder needs to be handled in the click event
            // https://github.com/ai/audio-recorder-polyfill/issues/86#issuecomment-772182894
            if (!checkWebmSupported_aka_isThisNotApple()) { //if ios/safari
                mediaRecorder.current.start(300)
            }
        }

    };

    const handleStopRecording = () => {
        //event.stopPropagation()
        if (recordingEventExclusion) {
            setRecordingEventExclusion(false)
            setTimeout(() => { //keep recording for a split second after letting go
                if (socket.current) {
                    socket.current.send(JSON.stringify({ _tag: 'SpeakEnd' }));
                }
                if (mediaRecorder.current) {
                    mediaRecorder.current.stop();
                }
            }, 200);
        } else console.log('skip stop handler')

    };

    const buttonState = useMemo(() => {
        if (socket.current === null || !switchState) {
            return ButtonStatus.OFF;
        } else if (O.isSome(speaking) && speaking.value !== user) {
            return ButtonStatus.BLOCKED;
        } else if (O.isSome(speaking) && speaking.value === user) {
            return ButtonStatus.ACTIVE;
        } else {
            return ButtonStatus.ON;
        }
    }, [socket.current, speaking, user, switchState]);

    return (
        <div className='layout'
            onTouchEnd={handleStopRecording} //handle cases where mouse/finger move off button
            onTouchCancel={handleStopRecording}
            onMouseUp={handleStopRecording}
            onClick={() => {
                // Enable wake lock.
                // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
                // document.addEventListener('click', function enableNoSleep() {
                // document.removeEventListener('click', enableNoSleep, false);
                // noSleep.enable();
                // }, false);
            }}
        >
            <div className='layoutHeaderBar'>
                <OnOffSwitch state={switchState} onToggle={() => setSwitchState(!switchState)} connecting={!!reconnectInterval.current} />
                <div className='layoutFlex' />
                {switchState ? <ParticipantList participants={participants} speaking={speaking} /> : null}
            </div>
            <div className='flexVertical'>
            
                <BigRedButton 
                    speakingUser={speaking}
                    status={buttonState} 
                    onStart={handleStartRecording} 
                    onStop={handleStopRecording}
                />
                <Whereby />
                <Pomodoro />
                <TranscribeWidget transcripts={transcripts} />
                
            </div>
            <div className='layoutHeaderBar'>
                <a onClick={() => auth.signOut()}><BiLogOutCircle /></a>
                <IoMdSettings />
                <WakeLock />
            </div>
        </div>
    )
}

