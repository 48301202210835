import { flow } from 'fp-ts/function';
import * as t from 'io-ts'
import { failure } from 'io-ts/lib/PathReporter';

export const showDecodeError = flow(failure, (errors) => errors.join('\n'))

export const textMessageCodec = t.union([
    t.type({
        _tag: t.literal('Users'),
        users: t.array(t.string)
    }),
    t.type({
        _tag: t.literal('Busy'),
        user: t.string
    }),
    t.type({
        _tag: t.literal('Free'),
    }),
    t.type({
        _tag: t.literal('Transcript'),
        user: t.string,
        text: t.string
    })
])

export type TextMessage = t.TypeOf<typeof textMessageCodec>;