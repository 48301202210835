import { initializeApp } from 'firebase/app';
import { Auth, User, getAuth } from 'firebase/auth';
import 'firebase/compat/auth';
import type { Option } from 'fp-ts/Option';
import * as O from 'fp-ts/Option';
import { useEffect, useState } from 'react';
import { P, match } from 'ts-pattern';
import './App.css';
import Login from './components/Login/Login';
import { UILayout } from './components/UILayout/UILayout';
import './styles/styles.scss';

const firebaseConfig = {
	apiKey: "AIzaSyBS56XoPl2vYzzlGlbFde15bdkFiVlT-Yg",
	authDomain: "currents-ukiyo.firebaseapp.com",
	projectId: "currents-ukiyo",
	storageBucket: "currents-ukiyo.appspot.com",
	messagingSenderId: "746106016641",
	appId: "1:746106016641:web:f5e914f17133a682d74e80"
};

function App(): JSX.Element {
    const [auth, setAuth] = useState<Option<Auth>>(O.none);
    const [user, setUser] = useState<Option<User>>(O.none);
    useEffect(() => {
        const app = initializeApp(firebaseConfig); 
        const auth = getAuth(app);
        setAuth(O.some(auth));
    }, []);
    
  	// const auth = pipe(
    //     initializeApp(firebaseConfig)	
	// 	O.map(
	// 		app => getAuth(app) ? getAuth(app) 
	// 	)
  	// );

  
    const r = match([auth, user])
      .with([{ _tag: 'Some' }, { _tag: 'Some' }], ([auth, user]) => (
        // <BigRedButton user={user.value.displayName || user.value.email || ''} />
        <UILayout auth={auth.value} user={`${user.value.displayName} | ${user.value.email}` || ''} />
      ))
      .with([{ _tag: 'Some' }, { _tag: 'None' }], ([auth, _]) => (
        <Login auth={auth.value} onUserChanged={setUser}/>
      ))
      .with([{ _tag: 'None' }, P._], () => (
        <div>Initialising Firebase...</div>
      ))
      .exhaustive();

    return r;
    // return (
		// <div className="App">
		// 	{O.isSome(auth) ? <Login auth={auth.value} /> : <div>Initialising Firebase...</div>}
			
    //         {/* <Login auth={auth} /> */}
    //   <BigRedButton />
		// </div>
    // );
}

export default App;


/**
 * 
 * TS2740: Type 'Some<Auth>' is missing the following properties from type 'Auth': app, name, config, setPersistence, and 11 more.
    22 |   return (
    23 |     <div className="App">
  > 24 |       {O.isSome(auth) ? <Login auth={auth} /> : <div>Initialising Firebase...</div>}
       |                                ^^^^
    25 |     </div>
    26 |   );
    27 | }
 */